// page ready
$(document).ready(function () {
    // contact page logic
    $('#contactPageForm2').submit(function (e) {
        e.preventDefault();
        var a, b, c, d, _token;
        a = $('#name').val();
        b = $('#email').val();
        c = $('#msgTopic').val();
        d = $('#msgContent').val();
        _token = $('[name=_token]').val();
        if (bm_not_empty(a) && bm_not_empty(b) && bm_not_empty(c) && bm_not_empty(d)) {
            bc();
            $.ajax({
                url: 'contact',
                type: 'POST',
                data: {
                    name: a,
                    email: b,
                    subject: c,
                    content: d,
                    _token: _token
                },
                success: function (data, status, jqHXR) {
                    bg();
                    if (data == 'S_014') {
                        sw('תודה רבה', 'הודעתך נשלחה אלינו בהצלחה!', 's')
                    }
                    else {
                        sw('שגיאה', 'אירעתה שגיאה בעת שליחת ההודעה אנא נסה שנית', 'e')
                    }
                },
                error: function (data, status, jqHXR) {
                    bg();
                    sw('שגיאה', 'אירעתה שגיאה בעת שליחת ההודעה אנא נסה שנית', 'e')
                }
            });

        }
        else {
            sw('שגיאה', 'עליך למלאות את כל השדות', 'w')
        }

    });

});


// system Functions
function sw(title, content, type) {
    var finalType = 'warning';
    if (type == 's')
        finalType = 'success';
    if (type == 'e')
        finalType = 'error';
    if (type == 'w')
        finalType = 'warning';

    swal({title: title, text: content, type: finalType, confirmButtonText: "אישור"});
}

function bc() {
    $('body').append('<div id="blackCome"><h1>אנא המתן</h1><img src="assets/img/loader.gif"></div>');
}

function bg() {
    $('#blackCome').remove();
}

function bm_not_empty(val) {
    if (val == '' || val == ' ' || val == '  ' || val == '   ' || val == '    ')
        return false;
    return true;
}